import type React from 'react';
import HelpDesk from './HelpDesk';
import Options from './CommunicationOptions/Options';

interface ContainerProps {
    employeeName: string;
    faqLink: string;
    isChatAvailable: boolean;
    onOpenChat: () => void;
    showContact: boolean;
    showHelp: boolean;
    toggleShowPopUp: () => void;
    whatsappLink: string;
}

const Container: React.FC<ContainerProps> = ({
    employeeName, faqLink, isChatAvailable, onOpenChat, showContact, showHelp, toggleShowPopUp, whatsappLink,
}) => {
    if (showHelp) {
        return (<HelpDesk employeeName={employeeName} />);
    }

    if (showContact) {
        return (<Options faqLink={faqLink} isChatAvailable={isChatAvailable} onOpenChat={onOpenChat} toggleShowPopUp={toggleShowPopUp} whatsappLink={whatsappLink} />);
    }

    return null;
};
export default Container;
