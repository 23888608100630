import * as pubSubJS from 'pubsub-js';
import Cookie from 'js-cookie';
import CustomerServiceChatModel from './CustomerServiceChatModel';
import { document, window } from '../globals';

class CustomerServiceChat {
    private model: CustomerServiceChatModel;

    constructor(
        customerServiceChatModel: CustomerServiceChatModel,
    ) {
        this.model = customerServiceChatModel;
    }

    init() {
        if (!window) {
            return;
        }
        window.robin_settings = {
            hide_tab: true,
            mobile_hide_tab: true,
            tab_position: 'right',
        };
        window.robin_settings.callback = this.onRobinCallback.bind(this);

        this.bindListeners();
        this.loadRobinScript();
    }

    bindListeners() {
        pubSubJS.subscribe('openChatWindow', this.openChatWindow.bind(this));
    }

    loadRobinScript() {
        if (!document) {
            return;
        }
        const scriptTag = document.createElement('script');
        scriptTag.src = this.model.robinJSUrl;
        scriptTag.async = true;
        scriptTag.defer = true;

        document.head.appendChild(scriptTag);
    }

    onRobinCallback(event: string) {
        if (event === 'init') {
            this.onRobinInitialized();
        }
    }

    onRobinInitialized() {
        this.bindListeners();

        if (!CustomerServiceChatModel.isChatAvailable()) {
            CustomerServiceChat.onChatUnavailable();
            return;
        }

        this.model.loadAvailableCustomerServiceEmployees();

        if (!this.model.hasCustomerServiceEmployeeAvailable()) {
            CustomerServiceChat.onChatUnavailable();
            return;
        }

        this.model.loadChosenCustomerServiceEmployee();

        if (this.model.shouldOpenChatOnLoad()) {
            this.openChatWindow();
        }

        pubSubJS.publish('robinChatInitialized', this.model.getChosenCustomerServiceEmployee());
    }

    setPreferredCustomerServiceEmployeeCookie() {
        const d = new Date();
        // Add 1 day
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
        const chosenCustomerServiceEmployee = this.model.getChosenCustomerServiceEmployee();
        if (chosenCustomerServiceEmployee !== null) {
            Cookie.set(this.model.chatUserCookieName, chosenCustomerServiceEmployee.getEmail(), { expires: d });
        }
    }

    static onChatUnavailable() {
        pubSubJS.publish('robinChatUnavailable');
    }

    static isChatAvailable() {
        return CustomerServiceChatModel.isChatAvailable();
    }

    getCustomerServiceEmployee() {
        return this.model.getCustomerServiceEmployee();
    }

    openChatWindow() {
        if (CustomerServiceChatModel.isChatAvailable()) {
            const chosenCustomerServiceEmployee = this.model.getChosenCustomerServiceEmployee();
            if (chosenCustomerServiceEmployee !== null) {
                window?.__robin?.chat(chosenCustomerServiceEmployee.getEmail());
            }
            this.setPreferredCustomerServiceEmployeeCookie();
        } else if (this.model.hasUserPreferredCustomerServiceEmployee()) {
            window?.__robin?.show('chat');
        } else {
            window?.__robin?.show('contact_form');
        }
    }
}

export default CustomerServiceChat;
