import type React from 'react';
import Icon from '../../../stories/Atoms/Icon/Icon';

interface TopIconProps {
    isChatAvailable: boolean;
    showContact: boolean;
    showHelp: boolean;
}

const TopIcon: React.FC<TopIconProps> = ({ isChatAvailable, showContact, showHelp }) => (
    <>
        {isChatAvailable && <div className="gift-help__user__status" />}
        {(showHelp || showContact) && (
            <div className="gift-help__user__close">
                <Icon name='fa-xmark' />
            </div>
        )}
    </>

);

export default TopIcon;
