export default class CustomerServiceEmployeeMode {
    constructor(
        private readonly firstName: string,
        private readonly email: string,
        private readonly avatar: string,
    ) {}

    getName(): string {
        return this.firstName;
    }

    getEmail(): string {
        return this.email;
    }

    getAvatar(): string {
        return this.avatar;
    }
}
