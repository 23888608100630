import type React from 'react';
import Icon from '../../../../stories/Atoms/Icon/Icon';

interface WhatsAppProps {
    isChatAvailable:boolean;
    whatsappLink: string;
}

const WhatsApp: React.FC<WhatsAppProps> = ({ isChatAvailable, whatsappLink }) => (
    <>
        {(isChatAvailable && !!whatsappLink)
        && (
            <div
                className="gift-help__contact__item pointer"
            >
                <a href={whatsappLink} target="_blank" rel="noreferrer">
                    <Icon style='fa-brands' name='fa-whatsapp' />
                    WhatsApp
                </a>
            </div>
        )}
    </>
);

export default WhatsApp;
