import type React from 'react';
import Translation from '../../general/Translation/Translation';
import Icon from '../../../stories/Atoms/Icon/Icon';

interface HelpDeskProps {
    employeeName: string;
}

const HelpDesk: React.FC<HelpDeskProps> = ({ employeeName }) => (

    <div key="help" className="gift-help__message">
        <div><Translation pageString='translations' stringId='help_text' /></div>
        <Icon name='fa-comment' />
        <Icon name='fa-phone' />
        {' '}
        <Translation pageString='translations' stringId='met' />
        {' '}
        {employeeName}
    </div>
);

export default HelpDesk;
