import classNames from 'classnames';
import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import Icon from '../../../../stories/Atoms/Icon/Icon';

interface PhoneIconProps {
    setShowPopUp: () => void;
}

const PhoneIcon: React.FC<PhoneIconProps> = ({ setShowPopUp }) => (
    <button
        className={classNames('gift-help__contact__item pointer gift-help__button')}
        onClick={setShowPopUp}
        type="button"
    >
        <Icon name='fa-phone' />
        <Translation pageString="translations" stringId="bel" />
    </button>
);

export default PhoneIcon;
