import type React from 'react';
import Icon from '../../../stories/Atoms/Icon/Icon';
import useTranslate from '../../general/Translation/hooks/UseTranslate';

interface DetailsProps {
    intTel: string;
    showPhoneNumber: boolean;
    telNumber: string;
    toggleShowPopUp: () => void;
}

const Details: React.FC<DetailsProps> = ({ intTel, showPhoneNumber, telNumber, toggleShowPopUp }) => {
    const translate = useTranslate();
    const openingTimeException = translate('openingTimesTranslations', 'opening_time_exception', { strict: false });
    const serviceAvailableAt = translate('openingTimesTranslations', 'service_available_at');
    const openingTimes = translate('openingTimesTranslations', 'opening_times');

    const detailText = `${serviceAvailableAt} ${openingTimes}${openingTimeException}`;

    return (
        <>
            {showPhoneNumber
            && (
                <div className="gift-help__popup">
                    <Icon name='fa-phone' />
                    <a href={`tel: ${intTel}`} target="_blank" rel="noopener noreferrer">{telNumber}</a>
                    <div
                        className="gift-help__popup__note"
                        dangerouslySetInnerHTML={{ __html: detailText }}
                    />
                    <button className="gift-help__popup__close gift-help__button" onClick={toggleShowPopUp} type="button">
                        <Icon name='fa-xmark' />
                    </button>
                </div>
            )}
        </>
    );
};

export default Details;
