import React from 'react';
import { createRoot } from 'react-dom/client';
import { document, window } from '../../js/globals';
import type { AppProps } from './components/App';
import App from './components/App';
import TranslationProvider from '../general/Translation/TranslationProvider';
import type { Translation } from '../general/Translation/types/translations';
import WebshopContextProvider from '../general/WebshopContext/WebshopContextProvider';

const element = document?.getElementById('giftHelpApp');
const settingsData = element?.dataset.giftHelpSettings;

type Settings = AppProps['settings'] & {
    openingTimesTranslations: Translation;
    translations: Translation;
};

if (document && element && settingsData && window) {
    const settings = JSON.parse(settingsData) as Settings;
    const dictionary = {
        openingTimesTranslations: settings.openingTimesTranslations,
        translations: settings.translations,
    };

    const root = createRoot(document.getElementById('giftHelpApp') as Element);
    root.render(
        (
            <React.StrictMode>
                <TranslationProvider dictionary={dictionary}>
                    <WebshopContextProvider data={window.siteMetadata}>
                        <App settings={settings} />
                    </WebshopContextProvider>
                </TranslationProvider>
            </React.StrictMode>
        ),
    );
}
