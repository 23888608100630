import type React from 'react';
import FAQ from './FAQ';
import PhoneIcon from './PhoneIcon';
import WhatsApp from './WhatsApp';
import ChatIcon from './ChatIcon';

interface OptionsProps {
    faqLink: string;
    isChatAvailable: boolean;
    onOpenChat: () => void;
    toggleShowPopUp: () => void;
    whatsappLink: string;
}

const Options: React.FC<OptionsProps> = ({ faqLink, isChatAvailable, onOpenChat, toggleShowPopUp, whatsappLink }) => (
    <div className="gift-help__contact">
        <ChatIcon isChatAvailable={isChatAvailable} onOpenChat={onOpenChat} />
        <WhatsApp isChatAvailable={isChatAvailable} whatsappLink={whatsappLink} />
        <PhoneIcon setShowPopUp={toggleShowPopUp} />
        <FAQ faqLink={faqLink} />
    </div>
);
export default Options;
