import type React from 'react';
import { useEffect, useState } from 'react';
import * as pubSubJS from 'pubsub-js';
import Cookie from 'js-cookie';
import Details from './Details';
import useToggle from '../../general/hooks/useToggle';
import TopIcon from './TopIcon';
import CustomerServiceChat from '../../../js/giftHelp/CustomerServiceChat';
import CustomerServiceChatModel from '../../../js/giftHelp/CustomerServiceChatModel';
import { document } from '../../../js/globals';
import Container from './Container';
import { useWithImageUrl } from '../../general/WebshopContext/WebshopContext';

export interface AppProps {
    settings: {
        faqLink: string;
        telephoneNumber: string;
        telephoneNumberInternational: string;
        whatsAppLink: string;
    };
}

const customerServiceChat = new CustomerServiceChat(new CustomerServiceChatModel());
customerServiceChat.init();

const App: React.FC<AppProps> = ({ settings }) => {
    const withImageUrl = useWithImageUrl();
    const [showPhoneNumber, toggleShowPhoneNumber] = useToggle(false);
    const [showContact, toggleShowContact] = useToggle(false);
    const [showHelp, setShowHelp] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isChatAvailable, setIsChatAvailable] = useState(false);
    const [employeeName, setEmployeeName] = useState('');
    const [employeeImage, setEmployeeImage] = useState(withImageUrl('template/MLG/blank_contactperson.png'));

    const toggleBar = () => {
        toggleShowContact();
        if (showPhoneNumber) {
            toggleShowPhoneNumber();
        }
    };

    useEffect(() => {
        pubSubJS.subscribe('robinChatInitialized', () => {
            const chatAvailableFromRobin = CustomerServiceChat.isChatAvailable();
            setIsChatAvailable(chatAvailableFromRobin);

            if (chatAvailableFromRobin) {
                const chatEmployee = customerServiceChat.getCustomerServiceEmployee();
                if (chatEmployee !== null) {
                    setEmployeeName(chatEmployee.getName());
                    setEmployeeImage(chatEmployee.getAvatar());
                }
            }

            if (!Cookie.get('giftHelpMessageSeen')) {
                setShowHelp(true);
                setTimeout(() => setShowHelp(false), 3000);

                const cookieTime = new Date();
                cookieTime.setTime(cookieTime.getTime() + 24 * 60 * 60 * 1000);
                Cookie.set('giftHelpMessageSeen', 'true', { expires: cookieTime });
            }

            setLoaded(true);
        });

        pubSubJS.subscribe('robinChatUnavailable', () => {
            setEmployeeImage(withImageUrl('template/MLG/helpdesk-default-employee.jpg'));
            setLoaded(true);
        });

        const listener = (event: MouseEvent) => {
            const target = event.target as HTMLElement | null;
            if (target && target.closest('.js-startChatDirect')) {
                customerServiceChat.openChatWindow();
            }
        };

        document?.addEventListener('click', listener);

        return () => {
            pubSubJS.unsubscribe('robinChatInitialized');
            pubSubJS.unsubscribe('robinChatUnavailable');
            document?.removeEventListener('click', listener);
        };
    });

    return (
        <>
            {loaded && (
                <div className="gift-help js-gift-help">
                    <Details
                        intTel={settings.telephoneNumberInternational}
                        showPhoneNumber={showPhoneNumber}
                        toggleShowPopUp={toggleShowPhoneNumber}
                        telNumber={settings.telephoneNumber}
                    />
                    <div className="gift-help__inner">
                        <button
                            className="gift-help__user gift-help__button"
                            onClick={toggleBar}
                            type="button"
                            aria-label="Open & Close Gift Help"
                        >
                            <TopIcon
                                isChatAvailable={isChatAvailable}
                                showContact={showContact}
                                showHelp={showHelp}
                            />
                            <img
                                alt="Employee"
                                src={employeeImage}
                            />
                        </button>
                        <Container
                            employeeName={employeeName}
                            faqLink={settings.faqLink}
                            isChatAvailable={isChatAvailable}
                            onOpenChat={() => customerServiceChat.openChatWindow()}
                            showContact={showContact}
                            showHelp={showHelp}
                            toggleShowPopUp={toggleShowPhoneNumber}
                            whatsappLink={settings.whatsAppLink}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default App;
