/* eslint-disable camelcase */
import Cookie from 'js-cookie';
import CustomerServiceEmployeeModel from './CustomerServiceEmployeeModel';
import { window } from '../globals';

class CustomerServiceChatModel {
    public robinJSUrl: string;

    public chatUserCookieName: string;

    private urlHash: string | undefined;

    private availableCustomerServiceEmployees: CustomerServiceEmployeeModel[];

    private chosenCustomerServiceEmployee: CustomerServiceEmployeeModel | null;

    constructor() {
        this.availableCustomerServiceEmployees = [];
        this.chosenCustomerServiceEmployee = null;

        this.robinJSUrl = 'https://robincontentdesktop.blob.core.windows.net/external/robin/hwz3v70m.js?cache=busted';
        this.urlHash = window?.location.hash.substr(1);
        this.chatUserCookieName = 'chatUserEmail';
    }

    loadChosenCustomerServiceEmployee() {
        this.chosenCustomerServiceEmployee = this.getCustomerServiceEmployee();
    }

    getCustomerServiceEmployee(): CustomerServiceEmployeeModel | null {
        if (this.hasUserPreferredCustomerServiceEmployee()) {
            const preferredAvailableEmployee = this.availableCustomerServiceEmployees.find((customerServiceEmployee) => (
                customerServiceEmployee.getEmail() === Cookie.get(this.chatUserCookieName)
            ));

            if (preferredAvailableEmployee instanceof CustomerServiceEmployeeModel) {
                return preferredAvailableEmployee;
            }
        }

        return this.availableCustomerServiceEmployees[Math.floor(Math.random() * this.availableCustomerServiceEmployees.length)] || null;
    }

    loadAvailableCustomerServiceEmployees() {
        const customerServiceEmployees = window?.__robin?.getWebStoreUsers() || [];

        customerServiceEmployees.forEach((customerServiceEmployee) => {
            if (customerServiceEmployee.actualPresence === 'online') {
                this.availableCustomerServiceEmployees.push(
                    new CustomerServiceEmployeeModel(
                        customerServiceEmployee.firstName ?? '',
                        customerServiceEmployee.email ?? '',
                        customerServiceEmployee.avatar128 ?? '',
                    ),
                );
            }
        });
    }

    getChosenCustomerServiceEmployee(): CustomerServiceEmployeeModel | null {
        if (!(this.chosenCustomerServiceEmployee instanceof CustomerServiceEmployeeModel)) {
            this.loadChosenCustomerServiceEmployee();
        }

        return this.chosenCustomerServiceEmployee;
    }

    static isChatAvailable(): boolean {
        return window?.__robin?.getOnlineStatus() || false;
    }

    hasUserPreferredCustomerServiceEmployee(): boolean {
        return Cookie.get(this.chatUserCookieName) !== undefined;
    }

    hasCustomerServiceEmployeeAvailable(): boolean {
        return this.availableCustomerServiceEmployees.length > 0;
    }

    shouldOpenChatOnLoad(): boolean {
        return this.urlHash?.indexOf('showChat') !== -1;
    }
}

export default CustomerServiceChatModel;
