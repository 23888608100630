import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import Icon from '../../../../stories/Atoms/Icon/Icon';

interface FAQProps {
    faqLink: string;
}
const FAQ: React.FC<FAQProps> = ({ faqLink }) => (
    <div className="gift-help__contact__item">
        <a href={faqLink}>
            <Icon name='fa-question' />
            <Translation pageString='translations' stringId='faq' />
        </a>
    </div>
);

export default FAQ;
