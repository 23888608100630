import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import Icon from '../../../../stories/Atoms/Icon/Icon';

interface ChatIconProps {
    isChatAvailable: boolean;
    onOpenChat: () => void;
}

const ChatIcon: React.FC<ChatIconProps> = ({ isChatAvailable, onOpenChat }) => (
    <button
        className="gift-help__contact__item pointer gift-help__button"
        onClick={onOpenChat}
        type="button"
    >
        {
            isChatAvailable
                ? (
                    <span>
                        <Icon name='fa-comment' />
                        <Translation pageString='translations' stringId='chat' />
                    </span>
                )
                : (
                    <span>
                        <Icon name='fa-envelope' />
                        <Translation pageString='translations' stringId='mail' />
                    </span>
                )
        }

    </button>
);

export default ChatIcon;
